<template>
  <div class="home container"> 
    <div class="row">
        <div class="gallery col-lg-10 mx-auto">
            <div class="position-relative pt-3" v-if="galleryBanner && galleryBanner.banners">
                <img class="img-fluid gallery_logo" :src="logo_src" alt="logo"> 
             
                <b-carousel
                  class="gallery_carousel"
                  :interval="4000"
                  fade
                  ref="myCarousel"
                  >
                      <b-carousel-slide v-for="(item, i) in galleryBanner && galleryBanner.banners" :key="item.image_path + i" :img-src="item.image_path"></b-carousel-slide>
                  </b-carousel> 
                  <div class="shadow"></div>
            </div>
            <div class="pt-3" 
            v-if="info && info.is_main_page_video && info.is_main_page_video[0] 
            && Number(info.is_main_page_video[0].value) && info.main_page_video" 

            v-html="info && info.main_page_video && info.main_page_video[0].value"></div>
            <div class="main__content pt-3">
                <pre class="about_desc pt-3 pt-md-0" v-html="info && info.main_page_content && info.main_page_content[0].value"></pre>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'home',
    components: {
    },
    data() {
      return { 
        galleryBanner:null,
        logo_src : require("@/assets/images/logo.png")
        
      }
    }, 
    beforeMount(){
      this.$store.dispatch('getFetchData','/home/banner/list')
      .then((res)=> this.galleryBanner = res)
    },
    computed: {
      info(){
        return this.$store.state.info
      }
    },
    methods: {
     
    }
}
</script>
<style lang="scss">
iframe{
  height:500px;
}

  @media screen and (max-width:574px) {
    iframe{
      height:300px;
    }
  }

</style>