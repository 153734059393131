<template>
  <div class="news container">
    <div class="col-lg-10 mx-auto">
        <page-header :link_list="link_list" ></page-header>
        <div class="row" v-if="newsTubeList && newsTubeList.data">
              <div class="col-lg-9 col-md-8">
                <div class="news-video">
                  <div class="news-video__box my-3" v-for="tube in newsTubeList && newsTubeList.data" :key="tube.name + tube.id">
                    <div v-html="tube.youtube"></div>
                    <h3>{{tube.name}}</h3>
                    <p>{{tube.subtitle}}</p>
                  </div>
                </div>
              
              </div>
              <div class="col-lg-3 col-md-4">
                <div class="vertical-news">
                  <!-- <h1 class="my-2 color0">NEWS</h1> -->
                  <div class="row">
                    <div @click="$refs.popupImg.showImage(index)" class="col-md-12 col-sm-6 d-flex flex-column align-items-center vertical-news__item mb-3" v-for="(news,index) in newsList && newsList.data" :key="news.name + index">
                      <div class="vertical-news__img">
                        <img class="img-fluid" :src="news.image_path" alt="">
                      </div>
                      <div class="vertical-news__desc mt-2">
                        <h4>{{news.name}}</h4>
                        <pre v-html="news.subtitle"></pre>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div> 
        <empty-words v-else></empty-words>

        <light-box v-if="newsList && newsList.data.length > 0" ref="popupImg" :showThumbs="false" :showLightBox="false" :media="media"></light-box>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'

export default {
    name: 'news',
    components: {
      pageHeader,
      LightBox
    },
    data() {
      return {
        newsTubeList:null,
        newsList:null,
        link_list:{
          title:"News",
          link:[
            {
              href:'/press',
              name:'PRESS',
              display:true
            },
          ]
        }
      }
    }, 
    beforeMount(){
      this.$store.dispatch('getFetchData','/news/list')
      .then((res)=> this.newsTubeList = res)


      this.$store.dispatch('getFetchData','/news/show/list')
      .then((res)=> this.newsList = res)
    },

    computed:{
      media(){
        if(this.newsList && this.newsList.data.length > 0){
          return this.newsList.data.map((item) =>{
            return {
              thumb: item.image_path,
              src: item.image_path,
              caption: '', // Optional
              srcset: item.image_path// Optional for displaying responsive images
            }
          });
        }
      }
    },


}
</script>
<style lang="scss" scoped>

  .vertical-news{
    cursor: pointer;
    &__desc{
      h4{
        font-size: 13px;
      }
      pre{
        font-size: 12px;
      }
    }
    // &__img{
    //   max-width: 100px;
    // }
  }
  @media screen and (max-width:574px) {
    
    .vertical-news{
      &__desc{
        width:100%;
        text-align: center;
      }
      &__img{
          max-width: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;
      }
    }
    
  }
</style>
<style lang="scss" >
  .news iframe{
    height: 400px!important;
  }
  @media screen and (max-width:574px) {
    .news iframe{
      height:auto!important;
    }
  }
</style