
<template>
  <div class="header container">
    <div class="logo pt-1 pt-sm-0 mx-sm-auto mb-2">
        <img class="img-fluid" :src="logo_src" alt="logo" > 
    </div>
    <div class="d-flex justify-content-end">
        <a href="javascript:void(0)" class=" navbar-toggler" @click="open = !open" >
            <span class="navbar-toggler-icon" :class="{'open': open}">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </span>
        </a>
    </div>
    <div class="d-flex justify-content-center align-items-center">
        <b-nav :class="{'open': open}" >
            <template v-for="(route,index) in route_link" >
                <!-- v-if="
                    (index >=0 ) &&
                    (index == 3 && Number($store.state.info.enable_artists.value)) &&
                    (index == 8 && Number($store.state.info.enable_gallery.value))
                "  -->
                <b-nav-item v-if="route.display" :key="route.text + index"
                @click="open = !open" :to="route.url">
                    {{route.text}}
                </b-nav-item>
            </template>
        </b-nav>
        <!-- <pre class="" v-html="route.text"></pre> -->
    </div>
  </div>
</template>
<script>
    export default {
        data(){
            return{
                open:false,
                route_link : [
                    { url : "/",  text :'HOME 主頁', display :true},
                    { url : "/news",  text :'NEWS 最新消息', display :true},
                    { url : "/about",  text :'ABOUT 關於我們', display :true},
                    { url : "/artists",  text :'ARTIST 合作藝人', display :true},
                    { url : "/posters",  text :'POSTER 海報', display :true},
                    { url : "/legendStar",  text :'LEGEND STAR 無限傳奇新星歌唱大賽', display :true},
                    { url : "/shows/tvshow",  text :'SHOW 表演活動', display :true},
                    { url : "/production/movie",  text :'PRODUCTION 製作', display :true},
                    { url : "/gallery",  text :'GALLERY 相集', display :true},
                    { url : "/ticketing",  text :'TICKETING 售票', display :true},
                    { url : "/contact",  text :'CONTACT 聯絡我們', display :true},
                ],
                logo_src : require("@/assets/images/logo.png")
            }
        },
        components: {
        },
        methods:{
            arrayFilter(key){
                return this.route_link.filter((obj) => obj.url.indexOf(key)>-1)[0];
            }
        },
        computed: {
            info(){
                return this.$store.state.info
            },
            // new_route_link() {
            //     var list = [...this.route_link];
            //     var link_artists = list.filter((obj) => obj.url.indexOf('artists') > -1)[0]
            //     var link_gallery = list.filter((obj) => obj.url.indexOf('gallery') > -1)[0]
            //     console.log('ff',this.info.enable_artists[0]);
            //     Number(this.$store.state.info.enable_artists[0].value) ? link_artists.display = true : link_artists.display = false
            //     Number(this.$store.state.info.enable_gallery[0].value) ? link_gallery.display = true : link_gallery.display = false
            //     return list
            // }

        },
        watch:{
            info(newVal, oldVal) {
                Number(newVal.enable_artists[0].value) ? this.arrayFilter('artists').display = true : this.arrayFilter('artists').display = false;
                Number(newVal.enable_gallery[0].value) ? this.arrayFilter('gallery').display = true : this.arrayFilter('gallery').display = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
    .logo{
        max-width:195px;
    }
    .navbar-toggler{
        display: none;
        position: absolute;
        top: 30px;
        right: 30px;
        background: $primary-color-3;
        .navbar-toggler-icon{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .bar1, .bar2, .bar3 {
                width: 30px;
                height: 3px;
                background-color: #333;
                // margin: 4px 0;
                transition: 0.4s;
            }
            &.open{
                .bar1 {
                    transform: translate(0, 10px) rotate(-45deg);
                }
                .bar2 {opacity: 0;}
                .bar3 {
                    transform: translate(0, -10px) rotate(45deg);
                }
            }
        }
    }
    .nav{ 
        flex-wrap: nowrap;
        li{
            a{
                padding: 0.75rem 1.2rem;
                border-right:1px solid rgb(109 109 104 / 10%);
                background: #232322;
                transition: background 0.3s ease-in;
                font-size: 13px;
                color:#fff;
                &:hover,&:active,&.router-link-exact-active{
                    background: $primary-color-3;
                }
            }

            &:first-child{
                a {border-radius: 8px 0px 0px 8px;}
            }
            &:last-child{
                a {border-radius:0px 8px 8px 0px; border-right:0px;}
            }
        }
    }
    @media screen and (max-width:1200px) {

        .nav{ 
            li{
                
                a{
                    padding: 0.5rem;
                }
            }
        }
    }

    @media screen and (max-width:1000px) {

        .navbar-toggler{
            display: block;
        }
        .nav{ 
            transition: max-height 0.5s ease-in-out;
            max-height:0px;
            overflow: hidden;
            flex-wrap: wrap;
            &.open{
                max-height:100vh;
            }   
            li{
                width: 100%;
                a{
                    padding: 1rem 1.5rem;
                    width:100%;
                    border-right:0px;
                    border-bottom:1px solid rgb(109 109 104 / 10%)
                }
                    &:first-child{
                        a {border-radius: 0px;}
                    }
                    &:last-child{
                        a {border-radius:0px; }
                    }
            }
        }
    }

@media screen and (max-width:574px) {
    .logo{
        max-width:110px;
    }
}
</style>