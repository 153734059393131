<template>
  <div>
      <div class="page-content" v-if="dataList && dataList.data && dataList.data.length > 0">
            <div class="row mb-3" >
              <div class="p-2" :class="$route.name == 'poster' ? 'col-sm-6 col-md-4 col-lg-3' : 'col-lg-6' " v-for="(list,index) in dataList.data" :key="list.id">
                    <div v-if="!list.is_image" class="production-video__box mb-3" >
                        <div v-html="list.youtube"></div>
                      <p class="text-center">{{list.name}}</p>
                    </div>
                    <div v-else>
                        <div class="gallery_box p-3 h-100" style="cursor: pointer;" @click="$refs.popupImg.showImage(list.image_path_index-1)">
                            <div class="mb-2 d-flex justify-content-center align-items-center">
                              <img class="mx-auto" style="max-width:100%; max-height:100%" :src="list.image_path" :alt="list.name">
                            </div>
                            <p>{{list.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <empty-words v-else></empty-words>
      <light-box v-if="dataList && dataList.data && dataList.data.filter(item => item.image_path).length > 0" ref="popupImg" :showThumbs="false" :showCaption="true" :showLightBox="false" :media="media.filter(item => item.src != null)"></light-box>
   
      
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
export default {
    components: {
      LightBox
    },
    data() {
      return {
        dataList: null,
      }
    }, 
    props:[
      'page'
    ],
    computed:{
      media(){
        if(this.dataList && this.dataList.data ){
          return this.dataList.data.map((item) =>{
            return {
              thumb: item.image_path,
              src: item.image_path,
              caption: item.name, // Optional
              srcset: item.image_path// Optional for displaying responsive images
            }
          });
        }
      }
    }, 
    beforeMount(){
        this.$store.dispatch('getFetchData',`/${this.page}/${this.$route.name}/list`)
        .then((res)=> this.dataList = res)
    }, 
    created() {
      this.$watch(
        () => this.$route.name ,
        (toParams, previousParams) => {
          this.$store.dispatch('getFetchData',`/${this.page}/${this.$route.name}/list`)
          .then((res)=> this.dataList = res)
        }
      )
    }
}
</script>
<style lang="scss" scoped>
</style>