import { render, staticRenderFns } from "./posters.vue?vue&type=template&id=6785ffc2&scoped=true&"
import script from "./posters.vue?vue&type=script&lang=js&"
export * from "./posters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6785ffc2",
  null
  
)

export default component.exports