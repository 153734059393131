<template>
  <div class="artists container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div
          class="
            page-head
            d-flex
            flex-wrap
            align-items-center
            py-3
            justify-content-between
          "
        >
          <h1 class="page-head__title">​ARTIST</h1>
          <p class="page-head__desc">{{ artists_desc }}</p>
        </div>
        <div class="page-content" v-if="artistData && artistData.artists && artistData.artists.length > 0">
          <div
            class="row artist_box mb-3"
            v-for="artist in artistData && artistData.artists"
            :key="`artist_${artist.name}`"
          >
            <div class="col-md-6 mx-auto artist_box__banners">
              <b-carousel controls style="text-shadow: 1px 1px 2px #333">
                <b-carousel-slide
                  v-for="(banner, index) in artist.images"
                  :key="`banner_${index} ${artist.name}`"
                  :img-src="banner.image_path"
                >
                </b-carousel-slide>
              </b-carousel>
            </div>
            <!-- <div class="col-md-6 artist_box__desc pt-2">
              <p>
                <strong class="color0">{{ artist.name }}</strong>
              </p>
              <p>
                <strong class="color0">{{ artist.short_description }}</strong>
              </p>
              <small>作品包括</small>
              <pre v-html="artist.works"></pre>
            </div> -->
          </div>
        </div>
        <empty-words v-else></empty-words>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "artists",
  components: {},
  data() {
    return {
      artistData: null,
      artists_desc: `本公司設藝人管理部。旗下包括知名藝人及演員，廣告模特兒，專業活動司儀等。藝人管理部團隊擁有多年經驗，涉足範圍包括活動策劃、廣告企案及拍攝、演唱會等等，目標為客戶提供專業而合適的演藝人材。`,
    };
  },
  beforeMount() {
    this.$store
      .dispatch("getFetchData", "/artist/list")
      .then((res) => (this.artistData = res));
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.artist_box {
  align-items: center;
  &__banners {
    .carousel-inner {
      img {
        border-radius: 15px;
      }
    }
  }
  &__desc {
    font-size: 16px;
    small {
      font-size: 10px;
    }
  }
}
</style>