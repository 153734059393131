<template>
          <div class="page-head d-flex flex-wrap align-items-center py-3 justify-content-between">
              <h1 class="page-head__title mb-0 color0">{{link_list.title}}</h1>
              <div v-if="link_list.link" class="common-button-list">
                <template v-for="link of link_list.link" >
                    <router-link v-if="link.display" class="common"  :key="link.href" :to="link.href">{{link.name}}</router-link>
                </template>
              </div>
          </div>
</template>

<script>
export default {
    components: {
    },
    data() {
      return {
      }
    }, 
    props:[
        "link_list"
    ]

}
</script>
<style lang="scss" scoped>
</style>