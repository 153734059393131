<template>
  <div class="production container">
    <div v-if="info && Object.keys($store.state.info).length > 0" class="row">
      <div  class="col-lg-10 mx-auto">
        <page-header :link_list="new_link_list" ></page-header>
        <img-video-component page="production"></img-video-component>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import imgVideoComponent from '@/components/imgVideoComponent'

export default {
    name: 'production',
    components: {
      pageHeader,
      imgVideoComponent
    },
    data() {
      return {
        link_list:{
          title:"PRODUCTION",
          link:[
            {
              href:'/production/movie',
              name:'電影',
              display:true
            },
            {
              href:'/production/disc',
              name:'唱片',
              display: true 
            },
            {
              href:'/production/adv',
              name:'廣告',
              display:true
            },
            {
              href:'/production/concert',
              name:'演唱會',
              display:true
            },
            {
              href:'/production/print',
              name:'刊物出品',
              display:true
            },
          ]
        }
      }
    }, 
    // methods:{
    //     arrayFilter(key){
    //         return this.link_list.link.filter((obj) => obj.href.indexOf(key)>-1)[0];
    //     }
    // },
    computed: {
        info(){
            return this.$store.state.info
        },
        new_link_list() {
          var list = {...this.link_list};
          var link_disc = list.link.filter((obj) => obj.href.indexOf('disc')>-1)[0]
          if(this.info && Number(this.info.enable_disc[0].value)){
            link_disc.display = true 
          }else{
            link_disc.display = false
          }
          return list
        }
    },
    // watch:{
    //     info(newVal, oldVal) {
    //         Number(newVal.enable_disc[0].value) ? this.arrayFilter('disc').display = true : this.arrayFilter('disc').display = false;
    //     },
    //     '$route.params': {
    //         handler: function(search) {
    //             Number(this.info.enable_disc[0].value) ? this.arrayFilter('disc').display = true : this.arrayFilter('disc').display = false;
    //         },
    //         deep: true,
    //         immediate: true
    //     }
    //   }
  }
</script>
<style lang="scss" scoped>
</style>