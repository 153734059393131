<template>
  <div class="shows container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <page-header :link_list="link_list" ></page-header>
        <img-video-component page="show"></img-video-component>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import imgVideoComponent from '@/components/imgVideoComponent'
export default {
    name: 'legendStar',
    components: {
      pageHeader,
      imgVideoComponent
    },
    data() {
      return {
        link_list:{
          title:"LEGEND STAR",
        }
      }
    },

}
</script>
<style lang="scss" scoped>
 
</style>