<template>
  <div class="about container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <page-header :link_list="link_list" ></page-header>
        <div class="page-content" v-if="ticketData && ticketData.tickets && ticketData.tickets.length > 0">
          <div
            class="row ticket_box"
            v-for="ticket in ticketData.tickets"
            :key="`ticket${ticket.name}`"
          >
            <div class="col-md-4">
              <b-carousel
                id="about-carousel"
                :interval="4000"
                controls
                style="text-shadow:1px 1px 2px #333;"
              >
                <b-carousel-slide
                  v-for="(banner, index) in ticket.images"
                  :key="`ticket_id${index}`"
                  :img-src="banner.image_path"
                >
                </b-carousel-slide>

              </b-carousel>
            </div>
            <div class="col-md-8">
              <h2 class="mb-3 mt-3 mt-sm-0 color0">{{ticket.name}}</h2>
              <pre class="about_desc pt-3 pt-md-0" v-html="ticket.description"></pre>
              <a class="btn_buy" target="_blank" :href="ticket.google_link">{{ ticket.button_text || "購買"}}</a>
            </div> 
          </div>
        </div>
        <empty-words v-else></empty-words>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
export default {
    name: 'ticketing',
    components: {
      pageHeader,
    },
    data() {
      return {
        ticketData:null,
        link_list:{
          title:"TICKETING",
          // link:[
          //   {
          //     href:'/client',
          //     name:'CLIENT'
          //   },
          // ]
        }
      }
    }, 
    beforeMount(){
      this.$store.dispatch('getFetchData','/ticket/list')
      .then((res)=> this.ticketData = res)
    },
    computed: {
    },
    methods: {
    }

}
</script>
<style lang="scss" scoped>
</style>

<style lang="scss" >
.ticket .carousel .carousel-inner{
  height:400px;
  display: flex;
  align-items: center;
  img{
    margin:0 auto;
    height:100%;
    width: auto!important;
  }
}

  .ticket_box{
    padding-bottom: 3rem;
    &:nth-child(n+2){
      padding: 3rem 0;
      border-top:1px solid #fff;
    }
  }
  #app .btn_buy{
    display: inline-block;
    padding: 0.5rem 2.5rem;
    border-radius: 6px;
    margin: 0.5rem 0;
    font-size: 20px;
    border: 0px;
    box-sizing: border-box;
    color: #fefefe;
    border: 2px solid #fefefe;
    transition:background 0.5s ease, color 0.5s ease;
  }
  #app .btn_buy:hover{
    background-color: #fefefe;
    color:#000;
    border: 2px solid #fefefe;
  }

</style>