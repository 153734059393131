<template>
  <div class="contact container mb-5">
    <div class="row">
      <div class="col-lg-10 mx-auto">
          <div class="page-head d-flex flex-wrap align-items-center py-3 justify-content-between">
            <h1 class="color0 ">Contact Us</h1>

          </div>  
          <div class="page-content" v-if="info" >
            <div class="contact-content row">
              <div v-if="info && info.address && info.address[0].value" class="contact-content-address col-md-6">
                <iframe 
                  :src="info.google_map[0].value"
                  allowfullscreen=""
                  width="100%"
                  height="300"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div class="contact-content-info col-md-6">
                <div v-if="info && info.address && info.address[0].value" class="info mt-1">
                  <div class="label">地址</div>
                  <p>{{ info.address[0].value}}</p>
                </div>
                <div v-if="info && info.website && info.website[0].value" class="info">
                  <div class="label">網址</div>
                  <p>{{ info.website[0].value}}</p>
                </div>
                <div v-if="info && info.tel && info.tel[0].value" class="info">
                  <div class="label">電話</div>
                  <p>{{ info.tel[0].value}}</p>
                </div>
                <div v-if="info && info.email && info.email[0].value" class="info">
                  <div class="label">電郵</div>
                  <p> <a :href="'mailto:'+ info.email[0].value">{{ info.email[0].value}}</a></p>
                </div> 
              </div>
            </div>
          </div>
          <empty-words v-else></empty-words>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "contact",
  components: {},
  data() {
    return {
      isAddress:false
      // google_map:
      //   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.040578739471!2d114.1712878153042!3d22.276452649388624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404005c9ef3a8a3%3A0xf8965c775a619924!2sToi%20Shan%20Centre!5e0!3m2!1sen!2shk!4v1662529888546!5m2!1sen!2shk",
      // address: "觀塘觀塘道418號創紀之城5期17樓",
      // tel: "(852) 6331 0466",
      // email: "taddy@starmusichk.com",
    };
  },
  computed: {
    info(){
      return this.$store.state.info
    }
  },
};
</script>
<style lang="scss" scoped>
.contact-content-info{
  .label{
    color:#ff0000;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>