<template>
  <div class="client container">
    <div class="row">
      <div class="col-lg-10 mx-auto">

          <page-header :link_list="link_list" ></page-header>
          <div class="page-content" v-if="clientData && client_partners">
                <div class="row mb-3" >
                    <div class="col-md-12">
                        <p>我們曾合作的伙伴</p>
                        <div class="row">
                            <div class="col-md-4 client-box" v-for="partner in clientData && client_partners"
                                :key="`partner_${partner.name}`" >
                                <div class="client_box h-100">
                                  <div class="mb-2 d-flex justify-content-center align-items-center" style="height:150px">
                                      <img class="mx-auto" style="max-width:100%; max-height:100%" :src="partner.image_path" :alt="partner.name">
                                  </div>
                                  <p>{{partner.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <p>我們曾合作的藝人</p>
                        <div class="row">
                            <div class="col-12 client-box"  v-for="artist in client_artists"
                                :key="`artist${artist.name}`">
                                <div class="client_box h-100">
                                    <div class="mb-2 d-flex justify-content-center align-items-center" style="height:150px">
                                        <img class="mx-auto" style="max-width:100%; max-height:100%" :src="artist.image_path" :alt="artist.name">
                                    </div>
                                    <p>{{artist.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
          </div>
          <empty-words v-else></empty-words>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
export default {
    name: 'client',
    components: {
      pageHeader
    },
    data() {
      return {
        clientData:null,
        link_list:{
          title:"CLIENT",
          link:[
            {
              href:'/client',
              name:'CLIENT',
              display:true
            },
          ]
        }
      }
    }, 
    methods: {
    }, 
    beforeMount(){
      this.$store.dispatch('getFetchData','/about/client/list')
      .then((res)=> this.clientData = res)
    },
    computed:{
      client_partners(){
        if(this.clientData && this.clientData.clients){
          return this.clientData.clients.filter((item) => !item.is_artist)
        }
      },
      client_artists(){
        if(this.clientData && this.clientData.clients){
          return this.clientData.clients.filter((item) => item.is_artist)
        }
      }
    }

}
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

 .client-box{
    border-bottom: 1px solid #fff;
    margin-bottom:15px;
    img{
      margin:0 auto;
    }
    p{
      font-size: 16px;
      text-align: center;
    }
 }
</style>