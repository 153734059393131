import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import news from '../views/news.vue'
import artists from '../views/artists.vue'
import client from '../views/client.vue'
import press from '../views/press.vue'
import shows from '../views/shows.vue'
import posters from '../views/posters.vue'
import legendStar from '../views/legendStar.vue'
import production from '../views/production.vue'
import gallery from '../views/gallery.vue'
import ticketing from '../views/ticketing.vue'
import contact from '../views/contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: index,
    meta: { title: 'asb' }
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/client',
    name: 'client',
    component: client
  },
  {
    path: '/press',
    name: 'press',
    component: press
  },
  {
    path: '/artists',
    name: 'artists',
    component: artists
  },
  {
    path: '/posters',
    name: 'poster',
    component: posters
  },
  {
    path: '/legendStar',
    name: 'legend',
    component: legendStar
  },
  {
    path: '/shows/tvshow',
    name: 'tvshow',
    component: shows
  },
  {
    path: '/shows/concert',
    name: 'concert',
    component: shows
  },
  {
    path: '/shows/interview',
    name: 'interview',
    component: shows
  },
  {
    path: '/shows/charity',
    name: 'charity',
    component: shows
  },
  {
    path: '/production/movie',
    name: 'movie',
    component: production
  },
  {
    path: '/production/disc',
    name: 'disc',
    component: production
  },
  {
    path: '/production/adv',
    name: 'video',
    component: production
  },
  {
    path: '/production/concert',
    name: 'concert',
    component: production
  },
  {
    path: '/production/print',
    name: 'print',
    component: production
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: gallery
  },
  {
    path: '/ticketing',
    name: 'ticket',
    component: ticketing
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
