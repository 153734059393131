<template>
  <div class="gallery container">
    <div class="row" v-if="galleryBanner && galleryBanner.banners || galleryList && galleryList.galleries">
        <div class="col-lg-10 mx-auto">
            <div class="position-relative pt-2" v-if="galleryBanner">
                <img class="img-fluid gallery_logo" :src="logo_src" alt="logo"> 
             
                <b-carousel
                  class="gallery_carousel"
                  :interval="4000"
                  fade
                  ref="myCarousel"
                  >
                      <b-carousel-slide v-for="(item, i) in galleryBanner && galleryBanner.banners" :key="item.image_path + i" :img-src="item.image_path"></b-carousel-slide>
                  </b-carousel> 
                  <div class="shadow"></div>
            </div>
            <h1 class="color0">Gallery</h1>
            <p>Our Effort , Our Partner , Our Joys , Our  Starmusichk</p>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6 mb-3"
                  v-for="(banner, index) in galleryList && galleryList.galleries"
                  :key="`banner_${index}`">
                    <div class="gallery_box p-3 h-100" style="cursor: pointer;" @click="$refs.popupImg.showImage(index)">
                      <div class="mb-2 d-flex justify-content-center align-items-center" style="height:150px">
                        <img :src="banner.image_path"  class="mx-auto" style="max-width:100%; max-height:100%"  alt="">
                    </div>
                </div>

              </div>
            </div>
        </div>
        <light-box v-if="galleryList" ref="popupImg" :showThumbs="false" :showLightBox="false" :media="media"></light-box>
    </div>
    <empty-words v-else></empty-words>
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
// import VueLazyload from 'vue-lazyload'
export default {
    name: 'gallery',
    components: {
      LightBox
    },
    // directives: {
    //   VueLazyload,
    // },
    data() {
      return {
        galleryBanner:null,
        galleryList:null,
        logo_src : require("@/assets/images/logo.png")
        // gallery:[

        //   {
        //     image_path:require("@/assets/images/about-banner1.webp")
        //   },
        //   {
        //     image_path:require("@/assets/images/about-banner2.webp")
        //   },
        //   {
        //     image_path:require("@/assets/images/about-banner3.webp")
        //   },
        // ],
      
      }
    }, 
    beforeMount(){
      this.$store.dispatch('getFetchData','/gallery/banner/list')
      .then((res)=> this.galleryBanner = res)

      this.$store.dispatch('getFetchData','/gallery/list')
      .then((res)=> this.galleryList = res)
    },
    computed:{
      media(){
        if(this.galleryList && this.galleryList.galleries){
          return this.galleryList.galleries.map((item) =>{
            return {
              thumb: item.image_path,
              src: item.image_path,
              caption: '', // Optional
              srcset: item.image_path// Optional for displaying responsive images
            }
          });
        }
      }
    },
    methods: {
    }

}
</script>
<style lang="scss" scoped>

</style>