import Vue from 'vue'
import Vuex from 'vuex'
import axiosIns from '@/libs/axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info: {},
    loading: false

  },
  getters: {
    getInfo(state) {
      return state.info
    }
  },
  mutations: {
  },
  actions: {
    fetchInfo({ state }, queryParams) {
      return new Promise((resolve, reject) => {
        state.loading = true
        axiosIns
          .get("/info")
          .then((response) => resolve(
            state.info = response.data.info,
            state.loading = false
          ))
          .catch((error) => reject(error, state.loading = false));
      });
    },
    getFetchData({ state }, url) {
      return new Promise((resolve, reject) => {
        state.loading = true
        axiosIns
          .get(url + '?sortBy=id&sortDesc=false')
          .then((response) => {
            let initialIndex = 0;
            if (response.data.data) {

              let dataItem = response.data.data.map(item => {
                if (item.image_path != null) initialIndex++;
                return { ...item, image_path_index: initialIndex }
              })

              response.data.data = dataItem
            } 
            resolve(response.data, state.loading = false)
          })
          .catch((error) => reject(error, state.loading = false));
      });
    }
  },
  modules: {
  }
})
