
<template>
  <div id="app">
    <layout-header></layout-header>
    <transition name="fade" mode="out-in">
        <router-view class="bodyPage"></router-view>
    </transition>
    <layout-footer></layout-footer>
  </div>
</template>
<script>
  // document.addEventListener("contextmenu", function (e){
  //     e.preventDefault();
  // }, false);
  import layoutHeader from "@/layouts/layoutHeader.vue"
  import layoutFooter from "@/layouts/layoutFooter.vue"
  document.addEventListener('contextmenu', e => e.preventDefault());
  document.addEventListener('copy', e => {
    e.preventDefault();
  });
  const images = document.querySelectorAll('img');
  images.forEach(img => {
    img.draggable = false;
  });
  document.addEventListener('dragstart', evt => {
    evt.preventDefault();
  });

  export default {
      components: {
        layoutHeader,
        layoutFooter
      },
      beforeMount(){
        this.$store.dispatch('fetchInfo');
      },
  }
</script>
<style lang="scss">
@import "@/assets/style/style.scss";
.fade-enter-active,
.fade-leave-active{
   transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to{
  opacity: 0;
}
// .fade-enter-active, .fade-leave-active {
//   transition-property: opacity;
//   transition-duration: .15s;
// }

// .fade-enter-active {
//   transition-delay: .15s;
// }

// .fade-enter, .fade-leave-active {
//   opacity: 0
// }
body{
  -webkit-touch-callout: none;
  user-select: none;
}
img {
  -webkit-user-select: none;  
  user-select: none;
}
</style>
