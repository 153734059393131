import { render, staticRenderFns } from "./emptyWords.vue?vue&type=template&id=8b59a148&scoped=true&"
import script from "./emptyWords.vue?vue&type=script&lang=js&"
export * from "./emptyWords.vue?vue&type=script&lang=js&"
import style0 from "./emptyWords.vue?vue&type=style&index=0&id=8b59a148&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b59a148",
  null
  
)

export default component.exports