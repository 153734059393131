<template>
  <div class="shows container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <page-header :link_list="link_list" ></page-header>
        <img-video-component page="show"></img-video-component>
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import imgVideoComponent from '@/components/imgVideoComponent'
export default {
    name: 'shows',
    components: {
      pageHeader,
      imgVideoComponent
    },
    data() {
      return {
        link_list:{
          title:"SHOW",
          link:[
            {
              href:'/shows/tvshow',
              name:'電視節目',
              display:true
            },
            {
              href:'/shows/concert',
              name:'演唱會花絮',
              display:true
            },
            {
              href:'/shows/interview',
              name:'訪問及直播',
              display:true
            },
            {
              href:'/shows/charity',
              name:'慈善活動項目',
              display:true
            },
          ]
        }
      }
    },

}
</script>
<style lang="scss" scoped>
// .status{
//   position:absolute;
//   right:20px;
//   top:20px;
//   font-size: 24px;
// }
 
</style>