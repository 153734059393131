<template>
<div v-if="!$store.state.loading" class="empty-words">
  No marching records found
  </div>

</template>
<script>
    export default {
      
      computed: {
        info(){
          return this.$store.state.info
        }
      },
    }
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
  .empty-words{
    font-size: 48px;
    color: #ff0000;
    text-align: center;
    margin-top: 7%;
  }
</style>