<template>
  <div class="shows container">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <page-header :link_list="link_list" ></page-header>
        <img-video-component page="about"></img-video-component>
          <!-- <div class="page-content" v-if="pressData && pressData.presses">
                <div class="row mb-3" >
                    <div class="col-md-6 p-2" v-for="(tube,index) in pressData && pressData.presses" :key="tube.name + index">
                        <div class="production-video__box my-3" >
                          <div v-html="tube.youtube"></div>
                          <h3>{{tube.name}}</h3>
                        </div>
                    </div>
                </div>
          </div>
          <empty-words v-else></empty-words> -->
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/pageHeader'
import imgVideoComponent from '@/components/imgVideoComponent'
export default {
    name: 'press',
    components: {
      pageHeader,
      imgVideoComponent
    },
    data() {
      return {
        link_list:{
          title:"PRESS",
          pressData:{},
          link:[
            {
              href:'/press',
              name:'PRESS',
              display:true
            },
          ]
        }
      }
    }, 
}
</script>
<style lang="scss" scoped>

 
</style>