
<template>
  <div class="footer container py-5">
    <div class="d-flex justify-content-between align-items-center">
        <div>
          <p>©Star Music Entertainment {{new Date().getFullYear()}}</p>
        </div>
        <div class="d-flex flex-wrap justify-content-end align-items-center">
          <span class="mb-2">
            <a class="fb-btn mr-3" target="_blank" :href="info && info.instagram && info.facebook[0].value">
              <img :src="require('@/assets/images/fb.webp')" alt="">
            </a>
            <a class="ig-btn mr-3" target="_blank" :href="info && info.instagram && info.instagram[0].value">
              <img width="40" :src="require('@/assets/images/instagram.png')" alt="">
            </a>
          </span>
          <img :src="require('@/assets/images/footer.png')" alt="">
        </div>
    </div>
    <!-- <div class="floating-btn-list">
      <a href="javascript:void(0)" @click="handleMute">
        <div class="float-icon">
        <i class="bi bi-volume-up-fill"></i>
        </div>
        <p>mute</p>
      </a>
    </div> -->
  </div>
</template>
<script>
    // const someSound = require("@/assets/drop-it-124014.mp3");
    // import 'bootstrap-icons/font/bootstrap-icons.css'
    export default {
        data(){
            return{
                // musicOn:true,
                // audio: new Audio(require('../assets/drop-it-124014.mp3'))
            }
        },
        computed: {
          info(){
            return this.$store.state.info
          }
        },
        methods:{
          // handleMute(){
          //   this.musicOn = ! this.musicOn;
          // },
          // handlePlay(){
          //   let audio = new Audio(this.someSound);
          //       audio.play();
          // }
        },
        mounted() {
          // this.handlePlay();
        }
    }
</script>

<style lang="scss" scoped>

  .floating-btn-list{
    position: fixed;
    top: 35%;
    right: 1rem;
    > a {
      text-align: center;
      .float-icon{
        border: 1px solid #fff;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
          font-size: 24px;
        }
      }
    }
  }
.fb-btn{
    
}
</style>